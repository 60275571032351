import {Injectable} from '@angular/core';
import {Evenement} from './evenement/evenement';
import {Observable, Subject} from 'rxjs';
import {Settings} from '../settings';
import {HttpClient} from '@angular/common/http';
import {Cacheable} from 'ts-cacheable';
import {map} from 'rxjs';

declare var GetSheetDone: any;

const cachedEvents = new Subject<void>();

@Injectable()
export class EvenementenService {

  constructor(
    private http: HttpClient) {
  }

	@Cacheable({
		cacheBusterObserver: cachedEvents
	})
	getEvenementenFromGoogleDrive(): Observable<Evenement[]> {
    return this.http.get(`https://docs.google.com/spreadsheets/d/${Settings.sheetId}/gviz/tq?tqx=out:json&sheet=Evenementen`, { responseType: 'text' })
      .pipe(
        map((text: String) => {
          var rows = JSON.parse(text.substr(47).slice(0, -2)).table.rows;
          return rows.map(json => {
            const evenement = new Evenement();
            evenement.loadFromJson(json);
            return evenement;
          })
        }));
	}

	cache : Observable<Evenement[]>;

}
