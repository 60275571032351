import {Component, OnInit} from '@angular/core';
import {TeamsService} from "../teams/teams.service";
import {ActivatedRoute} from "@angular/router";
import {Team} from "../teams/team";
import {SpelersService} from "../spelers/spelers.service";
import {Speler} from "../spelers/speler";

@Component({
  selector: 'team',
  templateUrl: './team-page.component.html',
  styleUrls: ['./team-page.component.scss']
})
export class TeamComponent implements OnInit {

  public team: Team;
  public spelers: Speler[] = [];

  constructor(
    private route: ActivatedRoute,
    private teamService : TeamsService,
    private spelersService: SpelersService) {
  }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        this.teamService.getTeam(params['id']).subscribe(
          team => {
            this.team = team;
          });
        this.spelersService.getSpelersFromGoogleDrive().subscribe(
            spelers => {
              this.spelers = spelers
	              .filter(speler => speler.teamid == params['id'])
	              .sort(this.compareSpelers);
            }
        )
      });
  }

	private compareSpelers(a: Speler,b: Speler) {
  	    if (a.functie.length > 0 && b.functie.length === 0) {
  	    	return -1;
        }
		if (a.functie.length === 0 && b.functie.length > 0) {
			return 1;
		}
		if (a.naam < b.naam)
			return -1;
		if (a.naam > b.naam)
			return 1;
		return 0;
	}

}
