<div id="page-top">

  <!-- Navigation -->
  <a class="menu-toggle rounded" href="#">
    <i class="fa fa-bars"></i>
  </a>
  <nav id="sidebar-wrapper">
    <ul class="sidebar-nav">
      <li class="sidebar-brand">
        <a class="js-scroll-trigger" href="#page-top">VC Pelt</a>
      </li>
      <li class="sidebar-nav-item">
        <a class="js-scroll-trigger" href="#wedstrijden">Wedstrijden</a>
      </li>
      <li class="sidebar-nav-item">
        <a class="js-scroll-trigger" href="#algemeen">Nuttige info</a>
      </li>
      <li class="sidebar-nav-item">
        <a class="js-scroll-trigger" href="#teams">Ploegen</a>
      </li>
      <li class="sidebar-nav-item">
        <a class="js-scroll-trigger" href="#sponsors">Sponsors</a>
      </li>
      <li class="sidebar-nav-item">
        <a class="js-scroll-trigger" href="#locatie">Locatie</a>
      </li>
    </ul>
  </nav>

  <header class="headpage d-flex">
    <div class="container text-white  text-center">
      <div class="lid-worden-bubble buzz-out-on-hover">
        <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdbSiPPuZPgDdafnGSN6fDrTvYfxb-rMFe5HmDxuGEajVYS9A/viewform">
          <img src="assets/logo/lid_worden.png" class="img-fluid">
        </a>
      </div>

      <img src="assets/logo/logo-transparant.png" class="img-fluid">

      <div class="container" style="margin-top: 100px;">
        <ul class="list-inline mb-5">

          <li class="list-inline-item">
            <a class="social-link rounded-circle text-white mr-3" target="_blank" href="https://www.facebook.com/Volleybalclub-Pelt-218642658196756/">
              <i class="icon-social-facebook"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a class="social-link rounded-circle text-white mr-3" target="_blank" href="https://www.instagram.com/vcpelt/">
              <i class="icon-social-instagram"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a class="social-link rounded-circle text-white mr-3" target="_blank" href="mailto:bestuur@vcpelt.be">
              <i class="icon-envelope"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a class="social-link rounded-circle text-white mr-3" target="_blank" href="https://clubs.stanno.com/be/vc-pelt/clubcollectie">
              <i class="fa fa-shopping-cart"></i>
            </a>
          </li>


        </ul>
      </div>

      <evenementen id="evenementen"></evenementen>

    </div>
  </header>

  <section class="trooper-section" >
    <a href="https://www.trooper.be/vcpelt" target="_blank">
      <img src="assets/logo/trooper_banner.png" class="img-fluid" />
    </a>
  </section>


  <wedstrijden id="wedstrijden"></wedstrijden>
  <algemeen id="algemeen"></algemeen>
  <teams id="teams"></teams>
  <sponsors id="sponsors"></sponsors>

  <div id="locatie" style="width: 100%" class="mb-0">
    <iframe width="100%" height="300" src="https://maps.google.com/maps?width=100%&amp;height=300&amp;hl=en&amp;q=Jeugdlaan%208%2C%20Overpelt+(Sporthal%20VC%20Pelt)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
            frameborder="0" scrolling="no" marginheight="0" marginwidth="0"><a href="https://www.maps.ie/map-my-route/">

    </a></iframe>
  </div>

  <section class="text-center m-3" >
    <a href="https://www.gemeentepelt.be" target="_blank">
      <img src="assets/logo/pelt_footer_50px.png" class="img-fluid" />
    </a>
  </section>

  <!-- Scroll to Top Button-->
  <a class="scroll-to-top rounded js-scroll-trigger" href="#page-top">
    <i class="fa fa-angle-up"></i>
  </a>
</div>
