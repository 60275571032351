<section class="content-section">
  <div class="container">
    <div class="content-section-heading text-center">
      <h3 class="text-secondary mb-0">Ploegen</h3>
      <h2 class="mb-5 primary-title">Overzicht ploegen VC Pelt</h2>
    </div>
    <div class="row">
      <div [routerLink]="['/team', team.id]" class="col-sm-12 col-md-6 col-lg-4 mt-2" *ngFor="let team of ploegen">
        <span class="portfolio-item show-on-hover-parent">
          <span class="caption">
            <span class="caption-content">
              <h2 class="hidden">{{team.reeks}}</h2>
              <h3>{{team.naam}}</h3>
            </span>
          </span>
          <img class="img-fluid" src="{{team.imageUrl}}" alt="">
        </span>
      </div>
    </div>
  </div>
</section>
