import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Settings} from '../../settings';
import {Tornooi} from './tornooi';
import {HttpClient} from '@angular/common/http';
import {Cacheable} from 'ts-cacheable';
import {map} from 'rxjs';

const cachedtornooien = new Subject<void>();

@Injectable()
export class TornooienService {

  constructor(
    private http: HttpClient) {
  }

	@Cacheable({
		cacheBusterObserver: cachedtornooien
	})
	getTornooienFromGoogleDrive(): Observable<Tornooi[]> {
    return this.http.get(`https://docs.google.com/spreadsheets/d/${Settings.sheetId}/gviz/tq?tqx=out:json&sheet=Tornooien`, { responseType: 'text' })
      .pipe(
        map((text: String) => {
          var rows = JSON.parse(text.substr(47).slice(0, -2)).table.rows;
          return rows.map(json => {
            const tornooi = new Tornooi();
            tornooi.loadFromJson(json);
            return tornooi;
          })
        }));
	}

	cache : Observable<Tornooi[]>;
}
