import {Component, Input} from '@angular/core';
import {Tornooi} from "../tornooi";

@Component({
  selector: 'tornooi-display',
  templateUrl: './tornooi.component.html',
  styleUrls: ['./tornooi.component.scss']
})
export class TornooiComponent{

  @Input() tornooi: Tornooi;

  constructor() { }

}
