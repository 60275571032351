

<span class="wedstrijd-in-rangschikking"
      #overlayTrigger="cdkOverlayOrigin"
      (mouseenter)="triggerOverlay(overlayTrigger)"
      (mouseleave)="displayPopover = false"
      cdkOverlayOrigin
      [ngClass]="wedstrijd.getStatus(ploegnaam)">
</span>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="popoverTriggerOrigin"
  [cdkConnectedOverlayOpen]="displayPopover">
  <div class="match-info ">
    <div class="team">{{wedstrijd.thuis}} - {{wedstrijd.bezoekers}}</div>
    <div *ngIf="wedstrijd.isPlayed()">
      <span class="uitslag">{{wedstrijd.uitslag}}</span> {{wedstrijd.setUitslagen}}</div>
    <div> Datum: <b>{{wedstrijd.date | date: 'd MMMM YYYY'}} om {{wedstrijd.date | date: 'HH:mm' }}</b></div>
    <div> Sporthal: <b>{{wedstrijd.sporthal}}</b></div>

  </div>
</ng-template>

