<div class="mt-2 row tornooi"   >
  <div class="col-fixed-width-50 text-left datum">
      <p class="month">{{tornooi.datum | date: "MMM"}}</p>
      <p class="day">{{tornooi.datum | date: "d"}}</p>
  </div>
  <div class="col text-left pt-2" >
    <h6>
      <span class="team">Locatie: {{tornooi.locatie}}</span>
    </h6>
     <p class="card-subtitle mb-2 text-muted">Hoelaat: van {{tornooi.start}} tot {{tornooi.einde}}</p>

  </div>
</div>