export class Reeks {

  public id: string;
  public naam: string;

  loadFromJson(json: any) {
    this.id = json.id;
    this.naam = json.name;
  }
}
