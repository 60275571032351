export class Tornooi {

	public id: number;
	public datum: Date;
	public start: string;
	public einde: string;
	public locatie: string;
	public teamId: string;


	loadFromJson(json: any) {
		const dateParts = json.c[1].v.split('/');

		this.start = json.c[2].v;
		this.einde = json.c[3].v;
		this.locatie =  json.c[4].v;
		this.teamId = json.c[0].v
		this.datum = new Date(
			Number.parseFloat(dateParts[2]),
			Number.parseFloat(dateParts[1]) -1 ,
			Number.parseFloat(dateParts[0]),
			0, 0, 0);
	}

	static compareByDate(tornooi1 : Tornooi, tornooi2 : Tornooi) {
		if (tornooi1.datum < tornooi2.datum) {
			return -1;
		}

		if (tornooi1.datum > tornooi2.datum) {
			return 1;
		}
		return 0;
	}
}
