<a class="menu-toggle rounded" style="left: 15px !important;" href="#teams">
  <i class="fa fa-arrow-left"></i>
</a>
<header class="headpage d-flex">
  <div class="container text-white">
    <div class="row" >
      <div class="col-lg-6">
        <img class="img-fluid team-page-picture" src="{{team?.imageUrl}}" alt="">
      </div>
      <div class="col-lg-6">
        <h3>{{team?.naam}} ({{team?.reeks}})</h3>
        <div *ngIf="team?.trainingsuren">
          <strong>Trainingsuren</strong>
          <p style="white-space: pre;" class="ml-1">{{team.trainingsuren}}</p>
        </div>
        <div *ngIf="team?.hbvlLink" class="mb-3">
          <strong><a class="link-white" href="{{team.hbvlLink}}" target="_blank"><span class="icon-link"></span> Zaalsportgids (HBVL)</a></strong>
        </div>
        <div *ngIf="spelers.length > 0">
          <strong>Ploegleden</strong>
          <p>
            <span *ngFor="let speler of spelers" class="ml-1">
              <i class="fa fa-user-circle" aria-hidden="true"></i>
              {{speler.naam}} <span *ngIf="speler.functie">({{speler.functie}})</span><br/></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</header>

<section class="bg-light" id="wedstrijden">
  <reeks-display *ngFor="let reeksId of team?.reeksIds" [reeksId]="reeksId"></reeks-display>
</section>

<section class="bg-light" id="tornooien">
  <tornooien-display teamId="team?.id"></tornooien-display>
</section>

<div *ngIf="team?.externeranking" class="text-center container">
  <div class="row">
    <div class="col-lg-12">
      <div class="p-3">
        <h2 class="mb-5 primary-title">Kalender / uitslagen / rankschikking</h2>
        <p>Deze informatie kan u terugvinden via volgende <a href="{{team?.externeranking}}" target="_blank">link</a>.</p>
      </div>
    </div>
  </div>
</div>
