import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Wedstrijd} from "./wedstrijd";
import {Observable, Subject} from "rxjs";
import {RangschikkingLijn} from "./rangschikkingLijn";
import {Cacheable} from 'ts-cacheable';
import {map} from 'rxjs';

const cachedMatches = new Subject<void>();

@Injectable()
export class WedstrijdenService {

	constructor(private http: HttpClient) {
	}

	@Cacheable({
		cacheBusterObserver: cachedMatches
	})
	getAlleWedstrijdenVoorClub(stamNumber: string): Observable<Wedstrijd[]> {
		return this.http.get<any[]>("https://www.volleyadmin2.be/services/wedstrijden_xml.php?format=json&stamnummer=" + stamNumber).pipe(
			map<any[], any[]>(wedstrijden => wedstrijden.filter(reeks => reeks)),
			map<any[], Wedstrijd[]>(wedstrijden => wedstrijden.map(item => {
				const wedstrijd = new Wedstrijd();
				wedstrijd.loadFromJson(item);
				return wedstrijd;
			})));
	}

	@Cacheable({
		cacheBusterObserver: cachedMatches
	})
	getAlleWedstrijdenVoorClubEnReeks(stamNumber: string, reeksId: number): Observable<Wedstrijd[]> {
		return this.http.get<any[]>("https://www.volleyadmin2.be/services/wedstrijden_xml.php?format=json&stamnummer=" + stamNumber + "&reeksId=" + reeksId).pipe(
			map<any[], any[]>(wedstrijden => wedstrijden.filter(reeks => reeks != false)),
			map<any[], Wedstrijd[]>(wedstrijden => wedstrijden.map(item => {
				const wedstrijd = new Wedstrijd();
				wedstrijd.loadFromJson(item);
				return wedstrijd;
			})));

	}

	@Cacheable({
		cacheBusterObserver: cachedMatches
	})
	getAlleWedstrijdenVoorReeks(reeksId: number): Observable<Wedstrijd[]> {
		if (reeksId != 4014) {
			return this.http.get<any[]>("https://www.volleyadmin2.be/services/wedstrijden_xml.php?format=json&province_id=4&reeksId=" + reeksId).pipe(
				map<any[], any[]>(wedstrijden => wedstrijden.filter(reeks => reeks != false)),
				map<any[], Wedstrijd[]>(wedstrijden => wedstrijden.map(item => {
					const wedstrijd = new Wedstrijd();
					wedstrijd.loadFromJson(item);
					return wedstrijd;
				})));
		} else {
			return this.http.get<any[]>("https://www.volleyadmin2.be/services/wedstrijden_xml.php?format=json&reeksId=" + reeksId).pipe(
				map<any[], any[]>(wedstrijden => wedstrijden.filter(reeks => reeks != false)),
				map<any[], Wedstrijd[]>(wedstrijden => wedstrijden.map(item => {
					const wedstrijd = new Wedstrijd();
					wedstrijd.loadFromJson(item);
					return wedstrijd;
				})));
		}
	}


	@Cacheable({
		cacheBusterObserver: cachedMatches
	})
	getRangschikking(reeksId: number): Observable<RangschikkingLijn[]> {
		if (reeksId != 4014) {
			return this.http.get<any[]>("https://www.volleyadmin2.be/services/rangschikking_xml.php?format=json&province_id=4&reeksId=" + reeksId).pipe(
				map<any[], RangschikkingLijn[]>(rangschikkingLijnen => rangschikkingLijnen.map(item => {
					const rl = new RangschikkingLijn();
					rl.loadFromJson(item);
					return rl;
				})));
		} else {
			return this.http.get<any[]>("https://www.volleyadmin2.be/services/rangschikking_xml.php?format=json&reeksId=" + reeksId).pipe(
				map<any[], RangschikkingLijn[]>(rangschikkingLijnen => rangschikkingLijnen.map(item => {
					const rl = new RangschikkingLijn();
					rl.loadFromJson(item);
					return rl;
				})));
		}
	}

}
