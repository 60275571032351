import {Pipe, PipeTransform} from '@angular/core';
import {ReeksenService} from "./reeksen.service";

@Pipe({
  name: 'reeksName', pure: false
})
export class ReeksNamePipe implements PipeTransform {

  constructor(private reeksenService : ReeksenService) {
  }

  transform(value: any, args?: any): any {
    if (this.reeksenService.alleReeksen) {
      let reeks = this.reeksenService.alleReeksen.find(reeks => reeks.id == value);
      if (reeks) {
        return reeks.naam;
      } else {
        return "reeks onbekend";
      }

    }
    return "";
  }

}
