import {Component, Input, OnInit} from '@angular/core';
import {Wedstrijd} from "../wedstrijd";
import {Settings} from "../../settings";
import {TeamsService} from "../../teams/teams.service";
import {Team} from "../../teams/team";
import {Router} from "@angular/router";

@Component({
  selector: 'wedstrijd-display',
  templateUrl: './wedstrijd.component.html',
  styleUrls: ['./wedstrijd.component.scss']
})
export class WedstrijdComponent implements OnInit {

  @Input() wedstrijd: Wedstrijd;
  @Input() showCalenderLink: boolean = false;

  public showExtraInfo : boolean = false;

  constructor(private teamService: TeamsService,
              private router: Router) { }

  ngOnInit() {

  }

  heeftClubGewonnen() : boolean {
    return this.wedstrijd.heeftClubGewonnen(Settings.stamNummer);
  }

  toggleExtraInfo() {
    this.showExtraInfo = !this.showExtraInfo;
  }

  navigateToTeam() {
    this.teamService.findTeamByReeksId(parseInt(this.wedstrijd.reeksId)).subscribe(team => this.navigateToTeamPage(team))
  }

  navigateToTeamPage(team : Team) {
    this.router.navigate(['/team', team.id]);
  }
}
