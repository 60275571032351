import {Component, Input, OnInit} from '@angular/core';
import {TornooienService} from "./tornooien.service";
import {Tornooi} from "./tornooi";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'tornooien-display',
  templateUrl: './tornooien.component.html'
})
export class TornooienDisplayComponent implements OnInit {

  public tornooien: Tornooi[];

  @Input() teamId: number;

  constructor(
      private tornooienService : TornooienService,
      private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params
        .subscribe(params => {
          this.tornooienService.getTornooienFromGoogleDrive().subscribe(tornooien => {
            this.tornooien = tornooien
                .filter(tornooi => tornooi.teamId == params['id']);
          });
        });
  }

}
