<!-- Services -->



<!--<popover-content #NuttigeLinksPopOver-->
<!--                 title="Nuttige links"-->
<!--                 placement="top"-->
<!--                 [animation]="true"-->
<!--                 [closeOnClickOutside]="true" >-->
<!--  <ul>-->
<!--    <li><a target="_blank" href="http://www.volleylimburg.be/#/klvv/home">Volley Limburg</a></li>-->
<!--    <li><a target="_blank" href="https://www.volleyvlaanderen.be/">Volley Vlaanderen</a></li>-->
<!--    <li><a target="_blank" href="https://www.volleyscores.be/vvb/">Volley Scores</a></li>-->
<!--    <li><a target="_blank" href="https://www.vks-limburg.be/">VKS Limburg</a></li>-->
<!--    <li><a target="_blank" href="https://www.volleyadmin2.be/">Volley Admin</a></li>-->
<!--    <li><a target="_blank" href="https://www.kuleuven.be/sport/universitair-sportcentrum/tussenkomst-in-het-lidgeld-van-een-sportclub-of-fitnessabonnement-via-uw-mutualiteit">Tussenkomst mutualiteiten</a></li>-->
<!--    <li><a target="_blank" href="assets/docs/VCP GDPR Privacyverklaring.pdf">VCP - GDPR Privacyverklaring</a></li>-->
<!--  </ul>-->
<!--</popover-content>-->


<section class="content-section bg-primary text-white text-center" >
  <div class="container">
    <div class="content-section-heading">
      <h3 class="text-secondary mb-0">Nuttige info</h3>
      <h2 class="mb-5">Alles wat je moet weten</h2>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6 mb-5 mb-md-5" (click)="isBestuurOpen = !isBestuurOpen" cdkOverlayOrigin #triggerBestuur="cdkOverlayOrigin">
        <a class="service-icon rounded-circle mx-auto mb-3">
              <em class="icon-mustache"></em>
            </a>
        <h4>
          <strong>Bestuur</strong>
        </h4>
        <p class="text-faded mb-0">Mail het bestuur</p>
      </div>
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="triggerBestuur"
        [cdkConnectedOverlayOpen]="isBestuurOpen">
        <div class="popup">
            <span class="functie-blok"><b class="functie-naam">Voorzitster</b><br/>Stephanie Bawin (<a href="mailto:voorzitter@vcpelt.be">voorzitter@vcpelt.be</a>)</span>
            <span class="functie-blok"><b class="functie-naam">Secretaris</b><br/> Stijn Maes (<a href="mailto:secretaris@vcpelt.be">secretaris@vcpelt.be</a>)</span>
            <span class="functie-blok"><b class="functie-naam">Penningmeester</b><br/>Pieter Reumers (<a href="mailto:penningmeester@vcpelt.be">penningmeester@vcpelt.be</a>)</span>
            <span class="functie-blok"><b class="functie-naam">Jeugdcoordinatoren</b><br/>Niels Paeyeneers &amp; Joeri Peeters (<a href="mailto:jeugd@vcpelt.be">jeugd@vcpelt.be</a>)</span>
            <span class="functie-blok"><b class="functie-naam">Seniorenploegen</b><br/>Casiano Gil (<a href="mailto:senioren@vcpelt.be">senioren@vcpelt.be</a>)</span>
            <span class="functie-blok"><b class="functie-naam">Pers &amp; Communicatie</b><br/>Sarah Jacobs (<a href="mailto:communicatie@vcpelt.be">communicatie@vcpelt.be</a>)</span>
            <span class="functie-blok"><b class="functie-naam">Scheidsrechters </b><br/>Pieter Reumers (<a href="mailto:secretaris@vcpelt.be">secretaris@vcpelt.be</a>)</span>

        </div>
      </ng-template>


      <div class="col-lg-3 col-md-6 mb-5 mb-md-5">
            <a target="_blank"  href="https://goo.gl/forms/8c8UOo3B1rgrUL8o2" class="service-icon rounded-circle mx-auto mb-3">
              <em class="icon-magic-wand"></em>
            </a>
        <h4>
          <strong>Verzekering</strong>
        </h4>
        <p class="text-faded mb-0">Wat te doen bij een ongeval</p>
      </div>
      <div class="col-lg-3 col-md-6 mb-5 mb-md-5">
            <a target="_blank"  href="assets/docs/Gedragscode Volleybalclub Pelt.pdf" class="service-icon rounded-circle mx-auto mb-3">
              <em class="icon-heart"></em>
            </a>
        <h4>
          <strong>Gedragscode</strong>
        </h4>
        <p class="text-faded mb-0">De geldende normen</p>
      </div>
      <div class="col-lg-3 col-md-6 mb-5 mb-md-5" (click)="isLinksOpen = !isLinksOpen" cdkOverlayOrigin #triggerLinks="cdkOverlayOrigin">
        <a class="service-icon rounded-circle mx-auto mb-3">
          <em class="icon-event"></em>
        </a>
        <h4>
          <strong>Nuttige links</strong>
        </h4>
        <p class="text-faded mb-0">Een wegwijzer naar meer informatie</p>
      </div>
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="triggerLinks"
        [cdkConnectedOverlayOpen]="isLinksOpen">
        <div class="popup">
            <ul>
              <li><a target="_blank" href="http://www.volleylimburg.be/#/klvv/home">Volley Limburg</a></li>
              <li><a target="_blank" href="https://www.volleyvlaanderen.be/">Volley Vlaanderen</a></li>
              <li><a target="_blank" href="https://www.volleyscores.be/vvb/">Volley Scores</a></li>
              <li><a target="_blank" href="https://www.vks-limburg.be/">VKS Limburg</a></li>
              <li><a target="_blank" href="https://www.volleyadmin2.be/">Volley Admin</a></li>
              <li><a target="_blank" href="https://www.kuleuven.be/sport/universitair-sportcentrum/tussenkomst-in-het-lidgeld-van-een-sportclub-of-fitnessabonnement-via-uw-mutualiteit">Tussenkomst mutualiteiten</a></li>
              <li><a target="_blank" href="assets/docs/VCP GDPR Privacyverklaring.pdf">VCP - GDPR Privacyverklaring</a></li>
              <li><a target="_blank" href="https://volleyvlaanderen.be/Gezondheid-en-ethiek/Federatie-API">Gezondheid en ethiek</a></li>
            </ul>
        </div>
      </ng-template>

    </div>
  </div>
</section>
