import {Component, OnInit} from '@angular/core';
import {Team} from "./team";
import {TeamsService} from "./teams.service";

@Component({
  selector: 'teams',
  templateUrl: './teams.component.html'
})
export class TeamsComponent implements OnInit {

  ploegen:  Team[] = [];
  constructor(private teamService : TeamsService) { }

  ngOnInit() {
    this.teamService.getTeamsFromGoogleDrive()
      .subscribe(teams => {
        this.ploegen = teams;
      });
  }

}
