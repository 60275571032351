<div class="h-100" *ngIf="evenement.link else eventNoLink">
  <a class="no-decoration h-100" href="{{evenement.link}}" target="_blank">
    <ng-container *ngTemplateOutlet="eventNoLink"></ng-container>
  </a>
</div>

<ng-template #eventNoLink>
  <div class="h-100 px-3 py-2">
      <div class="row event h-100" [class.ifLink]="evenement.link">
        <div class="col-fixed-width-50 text-left datum">
          <div *ngIf="evenement.datum else noDate">
            <p class="month">{{evenement.dateTime | date: "MMM"}}</p>
            <p class="day">{{evenement.dateTime | date: "d"}}</p>
          </div>
          <ng-template #noDate>
            <p class="nodate">?</p>
          </ng-template>
        </div>

        <div class="col text-left pt-2" >
          <h4>{{evenement.naam}}</h4>
          <div >
            <p class="card-subtitle mb-2 ml-2 text-muted">
              <span>{{evenement.uur}}</span>
              <span *ngIf="evenement.uur && evenement.locatie" class="px-1">|</span>
              <span>{{evenement.locatie}}</span>
            </p>
          </div>
        </div>
      </div>
  </div>
</ng-template>
