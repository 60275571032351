export class Speler {

	public naam: string;
	public functie: string;
	public teamid: string;

	loadFromJson(json: any) {
    this.naam = json.c[0]?.v;
    this.functie = json.c[1] ? json.c[1].v : "";
    this.teamid = json.c[2]?.v;
	}
}
