import {Team} from './team';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Settings} from '../settings';
import {HttpClient} from '@angular/common/http';
import {Cacheable} from 'ts-cacheable';
import {map} from 'rxjs';

const cachedTeams = new Subject<void>();

@Injectable()
export class TeamsService {

  constructor(
    private http: HttpClient) {
  }

	@Cacheable({
		cacheBusterObserver: cachedTeams
	})
	getTeamsFromGoogleDrive(): Observable<Team[]> {
    return this.http.get(`https://docs.google.com/spreadsheets/d/${Settings.sheetId}/gviz/tq?tqx=out:json`, { responseType: 'text' })
      .pipe(
        map((text: String) => {
          var rows = JSON.parse(text.substr(47).slice(0, -2)).table.rows;
          return rows.map(json => {
            const team = new Team();
            team.loadFromJson(json);
            return team;
          })
        }));
	}

	getTeam(teamId: number): Observable<Team> {
		return this.getTeamsFromGoogleDrive().pipe(
			map<Team[], Team>(teams =>
				teams.find(team => {
					return team.id == teamId
				})));
	}

	findTeamByReeksId(reeksId: number): Observable<Team> {
		return this.getTeamsFromGoogleDrive().pipe(
			map<Team[], Team>(teams =>
				teams.find(team => {
					return team.reeksIds.indexOf(reeksId) >= 0
				})));
	}
}
