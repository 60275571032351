import {Injectable} from "@angular/core";
import {Speler} from "./speler";
import {map, Observable, Subject} from 'rxjs';
import {Settings} from "../settings";
import {HttpClient} from '@angular/common/http';
import {Cacheable} from 'ts-cacheable';


const cachedSpelers = new Subject<void>();

@Injectable()
export class SpelersService {

  constructor(
    private http: HttpClient) {
  }

	@Cacheable({
		cacheBusterObserver: cachedSpelers
	})
	getSpelersFromGoogleDrive(): Observable<Speler[]> {
    return this.http.get(`https://docs.google.com/spreadsheets/d/${Settings.sheetId}/gviz/tq?tqx=out:json&sheet=Spelers`, { responseType: 'text' })
      .pipe(
        map((text: String) => {
          var rows = JSON.parse(text.substr(47).slice(0, -2)).table.rows;
          return rows.map(json => {
            const speler = new Speler();
            speler.loadFromJson(json);
            return speler;
          })
        }));
	}

	cache : Observable<Speler[]>;
}
