<section class="content-section bg-primary text-white text-center">
  <div class="container">
    <div class="content-section-heading text-center">
      <h3 class="text-secondary mb-0">Sponsors</h3>
      <h2 class="mb-5">Wij danken al onze sponsors!</h2>
    </div>
    <div class="row sponsors justify-content-center">
      <div class="col-sm-12 col-md-6 col-lg-4" *ngFor="let sponsor of sponsors">
        <a href="{{sponsor.link}}" target="_blank">
          <div class="sponsor">
            <img src="{{sponsor.imageUrl}}" alt="Image" class="img-fluid fade-on-hover">
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
