import {Component, Input, OnInit} from '@angular/core';
import {Settings} from "../settings";
import {Wedstrijd} from "../wedstrijden/wedstrijd";
import {WedstrijdenService} from "../wedstrijden/wedstrijden.service";
import {RangschikkingLijn} from "../wedstrijden/rangschikkingLijn";

@Component({
  selector: 'reeks-display',
  templateUrl: './reeks.component.html',
  styleUrls: ['./reeks.component.scss']
})
export class ReeksDisplayComponent implements OnInit {

  public wedstrijdenHeenRonde: Wedstrijd[];
  public wedstrijdenTerugRonde: Wedstrijd[];
  public rangschikkingLijnen: RangschikkingLijn[];
  private alleWedstrijden: Wedstrijd[];

  @Input() reeksId: number;

  constructor( private wedstrijdenService : WedstrijdenService) {

  }

  ngOnInit() {
    if (this.reeksId) {
        this.wedstrijdenService.getAlleWedstrijdenVoorClubEnReeks(Settings.stamNummer, this.reeksId).subscribe(wedstrijden => {
          this.wedstrijdenHeenRonde = wedstrijden.slice(0, wedstrijden.length /2);
          this.wedstrijdenTerugRonde = wedstrijden.slice(wedstrijden.length /2, wedstrijden.length);
        });
        this.wedstrijdenService.getRangschikking(this.reeksId).subscribe(rangschikkingLijnen => {
	        this.rangschikkingLijnen = rangschikkingLijnen;
            this.wedstrijdenService.getAlleWedstrijdenVoorReeks(this.reeksId).subscribe(alleWedstrijden => {
              this.linkWedstrijdenToRangschikkingsLijnen(alleWedstrijden);
            });
        });
      }
  }

  private linkWedstrijdenToRangschikkingsLijnen(alleWedstrijden: Wedstrijd[]) {
     this.rangschikkingLijnen.forEach(function (rangschikkingsLijn) {
       rangschikkingsLijn.wedstrijden = alleWedstrijden.filter(function (wedstrijd) {
          return wedstrijd.thuis === rangschikkingsLijn.ploegnaam || wedstrijd.bezoekers === rangschikkingsLijn.ploegnaam;
        });
     })
  }

  isHomeTeam(rangschikkingLijn: RangschikkingLijn) : boolean {
    return rangschikkingLijn.ploegnaam.toLowerCase().includes('pelt')
  }
}
