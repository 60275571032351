<section class="bg-light" id="wedstrijden">
  <div class="text-center container">
    <div class="row" >
      <div class="col-lg-6">
        <div class="p-3">
          <h2 class="mb-5 primary-title">Recente uitslagen</h2>
          <div *ngFor="let wedstrijd of laatstGespeeldeWedstrijden">
              <wedstrijd-display [wedstrijd]="wedstrijd" [showCalenderLink]="true"></wedstrijd-display>
          </div>
          <div *ngIf="laatstGespeeldeWedstrijden.length == 0">
              Er zijn geen recente uitslagen
          </div>
        </div>
      </div>
      <div class="col-lg-6" >
        <div class="p-3">
          <h2 class="mb-5 primary-title">Volgende wedstrijden</h2>
          <div *ngFor="let wedstrijd of eerstVolgendeWedstrijden">
            <wedstrijd-display [wedstrijd]="wedstrijd" [showCalenderLink]="true"></wedstrijd-display>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
