import { Component } from '@angular/core';

@Component({
  selector: 'algemeen',
  templateUrl: './algemeen.component.html',
  styleUrls: ['./algemeen.component.scss']
})
export class AlgemeenComponent {

  isBestuurOpen = false;
  isLinksOpen = false;

}
