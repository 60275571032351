import {Component, OnInit} from '@angular/core';
import {Evenement} from "./evenement/evenement";
import {EvenementenService} from "./evenementen.service";

@Component({
  selector: 'evenementen',
  templateUrl: './evenementen.component.html'
})
export class EvenementenComponent implements OnInit {

  evenementen:  Evenement[] = [];
  komendeEvenementen: Evenement[] = [];
  eerstvolgendeEvent: Evenement;

  constructor(private evenementenService : EvenementenService) { }

  ngOnInit() {
    this.evenementenService.getEvenementenFromGoogleDrive()
      .subscribe(evenementen => {
        this.evenementen = evenementen;
        this.komendeEvenementen = this.selectKomendeEvents(evenementen);
        this.eerstvolgendeEvent = this.selectEerstvolgendeEvent(evenementen)
      });
  }

  private selectKomendeEvents(alleEvents: Evenement[]){
    return alleEvents.filter(event => event.showEvent())
                      .filter(event => event.naam)
                      .sort(Evenement.compareByDate)
                      .slice(0,2);
  }

  public selectEerstvolgendeEvent(alleEvents: Evenement[]){
    return this.selectKomendeEvents(alleEvents)[0];
  }
}
