export class Team {

	public id: number;
	public naam: string;
	public reeks: string;
	public imageUrl: string;
	public reeksIds: number[] = [];
	public trainingsuren: string;
	public externeranking: string;
	public hbvlLink: string;


	loadFromJson(json: any) {
		this.id = json.c[0].v
		this.naam = json.c[1].v;
		this.reeks = json.c[2].v;
    this.trainingsuren = json.c[3]?.v;
    this.imageUrl = json.c[4]?.v;
		this.externeranking = json.c[7]?.v;
		this.hbvlLink = json.c[8]?.v;

		this.reeksIds = [];
		if (json.c[5]?.v) {
			this.reeksIds.push(json.c[5].v);
		}
		if (json.c[6]?.v) {
			this.reeksIds.push(json.c[6].v);
		}
	}
}
