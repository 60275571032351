<div class="text-center container">
  <div class="row">
    <div class="col-lg-6">
      <div class="p-3">
        <h2 class="mb-5 primary-title">Heenronde</h2>
        <div *ngFor="let wedstrijd of wedstrijdenHeenRonde">
          <wedstrijd-display [wedstrijd]="wedstrijd"></wedstrijd-display>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="p-3">
        <h2 class="mb-5 primary-title">Terugronde</h2>
        <div *ngFor="let wedstrijd of wedstrijdenTerugRonde">
          <wedstrijd-display [wedstrijd]="wedstrijd"></wedstrijd-display>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="text-center bg-primary text-white pb-5 pt-5" style="font-size: 17px">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="p-3">
          <div class="content-section-heading text-center">
            <h1 class="text-secondary mb-5">Rangschikking</h1>
          </div>
          <div class="row rankingrow-header">
            <div class="col-lg-3 col-md-3 col-sm-8 col-8" style="text-align: left">Ploeg</div>
            <div class="col">GW</div>
            <div class="col d-none d-md-block">3P</div>
            <div class="col d-none d-md-block">2P</div>
            <div class="col d-none d-md-block">1P</div>
            <div class="col d-none d-md-block">0P</div>
            <div class="col d-none d-md-block">S+</div>
            <div class="col d-none d-md-block">S-</div>
            <div class="col-2">Punten</div>
          </div>
          <div class="row rankingrow" *ngFor="let rangschikkingLijn of rangschikkingLijnen; index as i">
            <div class="col-lg-3 col-md-3 col-sm-8 col-8 team" [class.homeTeam]="isHomeTeam(rangschikkingLijn)">{{rangschikkingLijn.ploegnaam}}</div>
            <div class="col">{{rangschikkingLijn.aantalWedstrijden}}</div>
            <div class="col d-none d-md-block">{{rangschikkingLijn.gewonnen30_31}}</div>
            <div class="col d-none d-md-block">{{rangschikkingLijn.gewonnen32}}</div>
            <div class="col d-none d-md-block">{{rangschikkingLijn.verloren32}}</div>
            <div class="col d-none d-md-block">{{rangschikkingLijn.verloren30_31}}</div>
            <div style="color: #00ff00" class="col d-none d-md-block">{{rangschikkingLijn.aantalSetsGewonnen}}</div>
            <div style="color: #ff4242" class="col d-none d-md-block">{{rangschikkingLijn.aantalSetsVerloren}}</div>
            <div class="col-2"><b>{{rangschikkingLijn.aantalPunten}}</b></div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 d-none d-md-block">
        <div class="p-3">
          <div class="content-section-heading text-center">
            <h1 class="text-secondary mb-5">Rangschikking met matchen</h1>
          </div>
          <div class="row rankingrow-header">
            <div class="col-lg-4 col-md-4 col-sm-8 col-8" style="text-align: left">Ploeg</div>
            <div class="col">Wedstrijden</div>
            <div class="col-1">Punten</div>
          </div>
          <div class="row rankingrow" *ngFor="let rangschikkingLijn of rangschikkingLijnen; index as i">
            <div class="col-lg-4 col-md-4 col-sm-8 col-8 team" [class.homeTeam]="isHomeTeam(rangschikkingLijn)">{{rangschikkingLijn.ploegnaam}}</div>
            <div class="col " style="margin-top: 5px;">
              <wedstrijd-in-reeks *ngFor="let wedstrijd of rangschikkingLijn.wedstrijden" [wedstrijd]="wedstrijd"
                                  [ploegnaam]="rangschikkingLijn.ploegnaam"></wedstrijd-in-reeks>
            </div>
            <div class="col-1"><strong>{{rangschikkingLijn.aantalPunten}}</strong></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
