export class Evenement {

	public id: number;
	public naam: string;
	public datum: string;
	public uur: string;
	public locatie: string;
	public link: string;
	public dateTime: Date;


	loadFromJson(json: any) {
		const dateParts = json.c[2].v.split('/');

		this.id = json.c[0].v;
		this.naam = json.c[1].v;
		this.datum = json.c[2]?.v;
		this.uur = json.c[3]?.v;
		this.locatie = json.c[4]?.v;
		this.link = json.c[5]?.v;
		this.dateTime = new Date(
		      Number.parseFloat(dateParts[2]),
		      Number.parseFloat(dateParts[1]) -1 ,
		      Number.parseFloat(dateParts[0]),
          0, 0, 0);
	}

	static compareByDate(evenement1 : Evenement, evenement2 : Evenement) {
      if (evenement1.dateTime < evenement2.dateTime) {
        return -1;
      }

      if (evenement1.dateTime > evenement2.dateTime) {
        return 1;
      }
      return 0;
    }

    public showEvent() : boolean {
      if (this.dateTime.setHours(0,0,0,0) >= new Date().setHours(0,0,0,0)){
        return true;
      } else {
        return false;
      }
    }
}
