<div class="mt-2 row match" (click)="toggleExtraInfo()"  >
  <div class="col-fixed-width-50 text-left datum">
      <p class="month">{{wedstrijd.date | date: "MMM"}}</p>
      <p class="day">{{wedstrijd.date | date: "d"}}</p>
  </div>
  <div class="col text-left pt-2" >
    <h6>
      <span class="tijd">{{wedstrijd.date | date:"HH:mm"}} </span>
      <span class="team">{{wedstrijd.thuis}}</span>  - <span class="team">{{wedstrijd.bezoekers}}</span>

    </h6>
    <p class="card-subtitle mb-2 text-muted">{{wedstrijd.reeksId | reeksName}}
      &nbsp;<span class="calendar ml-2" *ngIf="showCalenderLink"><i class="fa fa-calendar" (click)="navigateToTeam()"></i> </span>
    </p>
    <p *ngIf="showExtraInfo" class="extra-info mb-2 text-muted">Sporthal: <strong>{{wedstrijd.sporthal}}</strong></p>
    <p *ngIf="showExtraInfo && wedstrijd.uitslag" class="extra-info mb-2 text-muted"> Setstanden: <strong>{{wedstrijd.setUitslagen}}</strong></p>
  </div>
  <div class="col-fixed-width-50 text-center score" [ngClass]="heeftClubGewonnen() ? 'score-won' : 'score-lost'" *ngIf="(wedstrijd.isPlayed() && wedstrijd.uitslag)">
    {{wedstrijd.uitslag}}
  </div>
</div>
