import { Component, OnInit } from '@angular/core';
import { Wedstrijd} from "./wedstrijd";
import { WedstrijdenService} from "./wedstrijden.service";
import {Settings} from "../settings";

@Component({
  selector: 'wedstrijden',
  templateUrl: './wedstrijden.component.html'
})
export class WedstrijdenComponent implements OnInit {

  laatstGespeeldeWedstrijden : Wedstrijd[] = [];
  eerstVolgendeWedstrijden : Wedstrijd[] = [];

  constructor(private wedstrijdenService : WedstrijdenService) { }

  ngOnInit() {
    this.wedstrijdenService.getAlleWedstrijdenVoorClub(Settings.stamNummer)
      .subscribe(alleWedstrijden => {
        this.laatstGespeeldeWedstrijden = this.selectLaatstGespeeldeWedstrijden(alleWedstrijden, 13);
        this.eerstVolgendeWedstrijden = this.selectEerstVolgendeeWedstrijden(alleWedstrijden, 13);
      });
  }

  private selectLaatstGespeeldeWedstrijden(alleWedstrijden: Wedstrijd[], limit: number) {
    return alleWedstrijden.filter(wedstrijd => (wedstrijd.isPlayed() && !(wedstrijd.date > new Date()))).sort(Wedstrijd.compareByDate).slice(-limit);
  }

  private selectEerstVolgendeeWedstrijden(alleWedstrijden: Wedstrijd[], limit: number) {
    return alleWedstrijden.filter(wedstrijd => !wedstrijd.isPlayed()).sort(Wedstrijd.compareByDate).slice(0, limit);
  }

}
