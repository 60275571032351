import {Component, Input} from '@angular/core';
import {Wedstrijd} from '../../wedstrijden/wedstrijd';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';

@Component({
  selector: 'wedstrijd-in-reeks',
  templateUrl: './wedstrijd-in-reeks.component.html',
  styleUrls: ['./wedstrijd-in-reeks.component.scss']
})
export class WedstrijdInReeksComponent {

  @Input() wedstrijd: Wedstrijd;

  @Input() ploegnaam: string;

  popoverTriggerOrigin: CdkOverlayOrigin;
  displayPopover = false;


  triggerOverlay(overlayTrigger: CdkOverlayOrigin): void {
    this.popoverTriggerOrigin = overlayTrigger;
    this.displayPopover = true;
  }

  constructor() { }

}
