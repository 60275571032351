import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Reeks} from "./reeks";
import {Observable, Subject} from "rxjs";
import {Cacheable} from 'ts-cacheable';
import {map} from 'rxjs';

const cachedReeksen = new Subject<void>();

@Injectable()
export class ReeksenService {
	public alleReeksen: any;

	constructor(private http: HttpClient) {
		this.getAlleReeksen().subscribe(reeksen => this.alleReeksen = reeksen);
	}

	@Cacheable({
		cacheBusterObserver: cachedReeksen
	})
	public getAlleReeksen(): Observable<Reeks[]> {
		//http://volleyadmin2.be/services/series_xml.php?format=json&province_id=4
		return this.http.get<any[]>("assets/reeksen.json").pipe(
			map<any[], any[]>(reeksen => reeksen.filter(reeks => reeks != false)),
			map<any[], Reeks[]>(reeksen => reeksen.map(item => {
				const reeks = new Reeks();
				reeks.loadFromJson(item);
				return reeks;
			})));
	}

}
