export class Wedstrijd {

  public date: Date;
  public thuis: string;
  public bezoekers: string;
  public reeks: string;
  public uitslag: string;
  public tijd: string;
  public sporthal: string;
  public reeksId: string;
  public setUitslagen: string;
  public wedstrijdnummer: string;
  private stamnummerThuis: string;
  private stamnummerBezoekers: string;

  loadFromJson(json: any) {
    const parts = json.t.split('/');
    const timeParts = json.Aanvangsuur.split(':');

    this.reeks = json.Reeks;
    this.thuis = json.Thuis.toLowerCase();
    this.bezoekers = json.Bezoekers.toLowerCase();
    this.uitslag = json.UitslagHoofd.replace(/ /g, '');
    this.date = new Date(Number.parseFloat(parts[2]), parts[1] - 1, Number.parseFloat(parts[0]),
      Number.parseFloat(timeParts[0]), Number.parseFloat(timeParts[1]), Number.parseFloat(timeParts[2]));
    this.tijd = json.time;
    this.sporthal = json.SporthalNaam;
    this.stamnummerThuis = json.stnr_home;
    this.stamnummerBezoekers = json.stnr_visitors;
    this.reeksId = json.ReeksId;
    this.setUitslagen = json.UitslagHoofd_set1 + "  " + json.UitslagHoofd_set2 + "  " + json.UitslagHoofd_set3 +
      "  " + json.UitslagHoofd_set4 + "  " + json.UitslagHoofd_set5;
    this.wedstrijdnummer = json.Wedstrijdnr;
  }


  static compareByDate(wedstrijd1 : Wedstrijd, wedstrijd2 : Wedstrijd) {
    if (wedstrijd1.date.getTime() < wedstrijd2.date.getTime()) {
      return -1;
    }

    if (wedstrijd1.date.getTime() > wedstrijd2.date.getTime()) {
      return 1;
    }
    return 0;
  }

  public isPlayed() : boolean {
    if (this.uitslag) {
      return true;
    } else {
      const fourHoursAgo = new Date();
      fourHoursAgo.setHours(fourHoursAgo.getHours() - 4);
      return this.date < fourHoursAgo;
    }
  }

  public getStatus(ploegnaam : string) : string {
    if (this.isPlayed()) {
      const homeScore = this.uitslag.split('-')[0];
      const guestScore = this.uitslag.split('-')[1];

      if (this.thuis == ploegnaam && homeScore > guestScore) {
        return "ploeg-gewonnen";
      } else if (this.bezoekers == ploegnaam && guestScore > homeScore) {
        return "ploeg-gewonnen";
      } else {
        return "ploeg-verloren";
      }
    } else {
      return "not-played-yet"
    }
  }

  public heeftClubGewonnen(stamnummer: string): boolean {
      try{
        const homeScore = this.uitslag.split('-')[0];
        const guestScore = this.uitslag.split('-')[1];

        if (this.stamnummerThuis == stamnummer && homeScore > guestScore) {
            return true;
        } else if (this.stamnummerBezoekers == stamnummer && guestScore > homeScore) {
            return true;
        } else {
            return false;
        }
      } catch {
            return false
      }
  }
}
