import {Wedstrijd} from "./wedstrijd";

export class RangschikkingLijn {

	public ploegnaam : string;
	public aantalWedstrijden: number;
	public aantalWedstrijdenGewonnen: number;
	public aantalWedstrijdenVerloren: number;
	public aantalSetsGewonnen: number;
	public aantalSetsVerloren: number;
	public aantalPunten: number;
	public gewonnen30_31: number;
	public gewonnen32: number;
	public verloren30_31: number;
	public verloren32: number;
	wedstrijden: Wedstrijd[];


	loadFromJson(json: any) {
		this.ploegnaam = json.Ploegnaam.toLowerCase();
		this.aantalWedstrijden = json.AantalWedstrijden;
		this.aantalWedstrijdenGewonnen = json.AantalWedstrijdenGewonnen;
		this.aantalWedstrijdenVerloren = json.AantalWedstrijdenVerloren;
		this.aantalSetsGewonnen = json.AantalSetsGewonnen;
		this.aantalSetsVerloren = json.AantalSetsVerloren;
		this.aantalPunten = json.AantalPunten;
		this.gewonnen30_31 = json.Gewonnen30_31;
		this.gewonnen32 = json.Gewonnen32;
		this.verloren30_31 = json.Verloren30_31;
		this.verloren32 = json.Verloren32;
	}
}
