import {Component, Input, OnInit} from '@angular/core';
import {Evenement} from "./evenement";

@Component({
  selector: 'evenement-display',
  templateUrl: './evenement.component.html',
  styleUrls: ['./evenement.component.scss']
})
export class EvenementComponent implements OnInit {

  @Input() evenement: Evenement;

  constructor() { }

  ngOnInit() {
  }
}
