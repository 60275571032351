import { Component, OnInit } from '@angular/core';
import {Sponsor} from "./sponsor";

@Component({
  selector: 'sponsors',
  templateUrl: './sponsors.component.html'
})
export class SponsorsComponent implements OnInit {

  sponsors: Sponsor[] = [];

  constructor() {

  }

  ngOnInit() {
    this.sponsors.push(new Sponsor("https://aregoverzekeringen.be/", "assets/sponsors/Arego.png"));
    this.sponsors.push(new Sponsor("https://bt-gofflo.be/", "assets/sponsors/BTGofflo.png"));
    this.sponsors.push(new Sponsor("https://driesennv.be/", "assets/sponsors/DriesenNV.jpg"));
    this.sponsors.push(new Sponsor("https://goliath-gs.be/", "assets/sponsors/Goliath.jpg"));
    this.sponsors.push(new Sponsor("https://goo.gl/maps/S35N6vtdKCP2", "assets/sponsors/oudemarkt.jpg"));
    this.sponsors.push(new Sponsor("http://www.metaalwerkengf.be/", "assets/sponsors/MetaalwerkenGF.png"));
    this.sponsors.push(new Sponsor("https://vbvd.be/dietist/zoek-een-dietist/dietiste-diabeteseducator-veerle-van-laerebeke", "assets/sponsors/veerle.jpg"));
  }

}
