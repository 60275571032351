import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {SponsorsComponent} from './sponsors/sponsors.component';
import {TeamsComponent} from './teams/teams.component';
import {WedstrijdenComponent} from './wedstrijden/wedstrijden.component';
import {AlgemeenComponent} from './algemeen/algemeen.component';
import {HttpClientModule} from "@angular/common/http";
import {WedstrijdComponent} from './wedstrijden/wedstrijd/wedstrijd.component';
import {WedstrijdenService} from "./wedstrijden/wedstrijden.service";
import localeNLBe from '@angular/common/locales/nl-BE';
import {registerLocaleData} from "@angular/common";
import {TeamsService} from "./teams/teams.service";
import {ReeksenService} from "./wedstrijden/reeksen.service";
import {ReeksNamePipe} from "./wedstrijden/reeks.pipe";
import {RouterModule, Routes} from "@angular/router";
import {HomeComponent} from './home/home.component';
import {TeamComponent} from "./team-page/team-page.component";
import {ReeksDisplayComponent} from "./reeks/reeks.component";
import {SpelersService} from "./spelers/spelers.service";
import {EvenementenComponent} from "./evenementen/evenementen.component";
import {EvenementenService} from "./evenementen/evenementen.service";
import {EvenementComponent} from "./evenementen/evenement/evenement.component";
import {TornooienDisplayComponent} from "./team-page/tornooien/tornooien.component";
import {TornooienService} from "./team-page/tornooien/tornooien.service";
import {TornooiComponent} from "./team-page/tornooien/tornooi/tornooi.component";
import {OverlayModule} from '@angular/cdk/overlay';
import {WedstrijdInReeksComponent} from './reeks/wedstrijd-in-reeks/wedstrijd-in-reeks.component';

const routes: Routes = [
	{path: '', component: HomeComponent,},
	{path: 'team/:id', component: TeamComponent},
	{path: "**", component: HomeComponent}
];

@NgModule({
	declarations: [
		AppComponent,
		SponsorsComponent,
		TeamsComponent,
		WedstrijdenComponent,
		AlgemeenComponent,
		WedstrijdComponent,
		TeamComponent,
		ReeksNamePipe,
		HomeComponent,
		ReeksDisplayComponent,
		ReeksDisplayComponent,
		EvenementenComponent,
		EvenementComponent,
		TornooienDisplayComponent,
		TornooiComponent,
    WedstrijdInReeksComponent
	],
	imports: [
		BrowserModule,
		HttpClientModule,
    OverlayModule,
		RouterModule.forRoot(routes, { useHash: true }),
	],
	providers: [
		{provide: LOCALE_ID, useValue: "nl-BE"},
		WedstrijdenService,
		TeamsService,
		ReeksenService,
		SpelersService,
		EvenementenService,
		TornooienService
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}

registerLocaleData(localeNLBe);
